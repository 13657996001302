import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import Slider from "react-slick";
import api from "../../utils/api";
import { OPTION_TIME } from "../../utils/constants";

const delay = ms => new Promise(res => setTimeout(res, ms));

export const Questions = forwardRef((props, ref) => {
  const { timer, updateTimer, showOptionBool, updateShowOptionBool } = props;
  let slider = useRef(null);
  const [option, setOpton] = useState(0);
  const [submit, answerSubmit] = useState(false);
  const [isAnswered, setAnsweredState] = useState(false);
  const [answer, setAnswer] = useState(-1);
  const [inital, setInitailLode] = useState(false);
  const [move, setMove] = useState(0);
  const [currentScore, updateCurrentScore] = useState(0);
  const [prevScore, updatePrevScore] = useState(0);
  const [score, updateScore] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    swipe: false,
    className: "sliderclass"
  };

  const setOptionsTimeout = () => {
    const currentIndexUtil =
      props.questionSet && props.questionSet.length && props.questionSet[move];
    const questionOffset = currentIndexUtil && currentIndexUtil.question_offset;
    const optionOffset = currentIndexUtil && currentIndexUtil.option_offset;
    let time_diff = optionOffset - questionOffset;
    time_diff = time_diff * 1000;
    const showOptionsTimeout = setTimeout(() => {
      updateShowOptionBool(true);
      updateTimer(OPTION_TIME);
    }, time_diff);
  };

  useImperativeHandle(ref, () => ({
    submitAndNav: async () => {
      const live_quiz_id =
        props.questionSet &&
        props.questionSet.length &&
        props.questionSet[0] &&
        props.questionSet[0].quiz_challenge_id;

      if (inital) {
        const totalQst = (props.questionSet && props.questionSet.length) || 0;

        if (totalQst > move + 1) {
          const questionIndexUtil =
            props.questionSet &&
            props.questionSet.length &&
            props.questionSet[move];

          const nextQuestionIndexUtil =
            props.questionSet &&
            props.questionSet.length &&
            props.questionSet[move + 1];

          const delayTime =
            (nextQuestionIndexUtil.question_offset -
              questionIndexUtil.option_offset -
              OPTION_TIME) *
              1000 || 0;

          const question_id = questionIndexUtil && questionIndexUtil.id;
          const question_answer =
            questionIndexUtil && questionIndexUtil.question_answer;
          const question_points =
            questionIndexUtil && questionIndexUtil.question_points;
          if (isAnswered) {
            await delay(delayTime - 750);
            setMove(move + 1);
            setAnswer(-1);

            setAnsweredState(false);
            setOpton(0);
            updateShowOptionBool(false);

            slider.current.slickGoTo(move + 1);
            setOptionsTimeout();
            console.log("wait time for " + move + " question:" + delayTime);
          } else {
            submitAnswer({
              optionNumber: -99,
              correctAnswer: question_answer,
              question_id,
              live_quiz_id,
              question_points
            });

            await delay(delayTime - 750);
            setMove(move + 1);
            setAnswer(-1);

            updateShowOptionBool(false);

            slider.current.slickGoTo(move + 1);
            setOptionsTimeout();
            console.log("wait time for " + move + " question:" + delayTime);
          }
        } else {
          if (isAnswered) {
            await delay(3000);

            props.history.replace("/results");
          } else {
            const questionIndexUtil =
              props.questionSet &&
              props.questionSet.length &&
              props.questionSet[move];

            const question_id = questionIndexUtil && questionIndexUtil.id;
            const question_points =
              questionIndexUtil && questionIndexUtil.question_points;
            const question_answer =
              questionIndexUtil && questionIndexUtil.question_answer;

            submitAnswer({
              optionNumber: -99,
              correctAnswer: question_answer,
              question_id,
              live_quiz_id,
              question_points
            });

            await delay(3000);

            props.history.replace("/results");
          }
        }
      }
    }
  }));

  const submitAnswer = ({
    optionNumber,
    correctAnswer,
    question_id,
    live_quiz_id,
    question_points
  }) => {
    let points = 0;
    if (optionNumber === correctAnswer) {
      points =
        // question_points - timer > 0 ? question_points - timer : 0;
        timer > 0 ? timer : 0;
      const cumulativePoints = currentScore + points;
      updatePrevScore(currentScore);
      updateCurrentScore(cumulativePoints);
    }
    if (!isAnswered) {
      if (optionNumber > 0) {
        setAnsweredState(true);
        setOpton(optionNumber);
        setAnswer(correctAnswer);
      }
      const answer = optionNumber ? optionNumber : -99;
      const answer_time = OPTION_TIME - timer;
      const action = Object.assign({});
      action.handler = "V1_CHALLENGES_SUBMITANSWER";
      action.payload = {
        answer,
        answer_time,
        question_id,
        live_quiz_id,
        current_points: points
      };
      const response = api(action);
      if (response.status === 200) {
        // props.history.push("/quiz/results");
        // const answer = response.data.correct_ans;
        // const user_answer = response.data.user_answer;
        // setAnswer(answer);
        // if (answer === user_answer) {
        //   const userScore = score;
        //   const newScore = userScore + 10;
        //   updateScore(newScore);
        // }
      } else {
        // console.log("answer submit fail");
      }
    }
  };

  useEffect(() => {
    setInitailLode(true);
  }, []);

  useEffect(() => {
    if (currentScore !== prevScore) {
      updateScore(currentScore);
    }
  }, [move]);

  return (
    <div>
      <div className="playHeader">
        <div>
          <p>
            {move + 1}/{(props.questionSet && props.questionSet.length) || 0}
          </p>
          <span>Question</span>
        </div>
        {props.quizStreamTime && props.showFirstQuestionBool ? (
          <div>
            <div className="round">
              <p>{timer}</p>
            </div>
          </div>
        ) : (
          <div />
        )}

        <div>
          <p>{score}</p>
          <span>Score</span>
        </div>
      </div>
      {props.quizStreamTime && props.showFirstQuestionBool ? (
        <Slider {...settings} ref={slider}>
          {props.questionSet &&
            props.questionSet.length &&
            props.questionSet.map(question => {
              return (
                <div key={question.id}>
                  <div className="qstnCont">
                    <p>{question.question_text}</p>
                  </div>
                  {showOptionBool ? (
                    <div className="optionCont">
                      <div
                        onClick={submitAnswer.bind(null, {
                          optionNumber: 1,
                          correctAnswer: question.question_answer,
                          question_id: question.id,
                          live_quiz_id: question.quiz_challenge_id,
                          question_points: question.question_points
                        })}
                        style={
                          isAnswered || timer === 0
                            ? { pointerEvents: "none" }
                            : { pointerEvents: "auto" }
                        }
                        className={
                          timer === 0
                            ? answer === 1
                              ? "options correct"
                              : option === 1
                              ? "options incorrect"
                              : "options"
                            : option === 1 && !submit
                            ? "options active"
                            : "options"
                        }
                      >
                        <p>{question.question_option_1}</p>
                      </div>
                      <div
                        onClick={submitAnswer.bind(null, {
                          optionNumber: 2,
                          correctAnswer: question.question_answer,
                          question_id: question.id,
                          live_quiz_id: question.quiz_challenge_id,
                          question_points: question.question_points
                        })}
                        style={
                          isAnswered || timer === 0
                            ? { pointerEvents: "none" }
                            : { pointerEvents: "auto" }
                        }
                        className={
                          timer === 0
                            ? answer === 2
                              ? "options correct"
                              : option === 2
                              ? "options incorrect"
                              : "options"
                            : option === 2 && !submit
                            ? "options active"
                            : "options"
                        }
                      >
                        <p>{question.question_option_2}</p>
                      </div>
                      <div
                        onClick={submitAnswer.bind(null, {
                          optionNumber: 3,
                          correctAnswer: question.question_answer,
                          question_id: question.id,
                          live_quiz_id: question.quiz_challenge_id,
                          question_points: question.question_points
                        })}
                        style={
                          isAnswered || timer === 0
                            ? { pointerEvents: "none" }
                            : { pointerEvents: "auto" }
                        }
                        className={
                          timer === 0
                            ? answer == 3
                              ? "options correct"
                              : option === 3
                              ? "options incorrect"
                              : "options"
                            : option === 3 && !submit
                            ? "options active"
                            : "options"
                        }
                      >
                        <p>{question.question_option_3}</p>
                      </div>
                      <div
                        onClick={submitAnswer.bind(null, {
                          optionNumber: 4,
                          correctAnswer: question.question_answer,
                          question_id: question.id,
                          live_quiz_id: question.quiz_challenge_id,
                          question_points: question.question_points
                        })}
                        style={
                          isAnswered || timer === 0
                            ? { pointerEvents: "none" }
                            : { pointerEvents: "auto" }
                        }
                        className={
                          timer === 0
                            ? answer == 4
                              ? "options correct"
                              : option === 4
                              ? "options incorrect"
                              : "options"
                            : option === 4 && !submit
                            ? "options active"
                            : "options"
                        }
                      >
                        <p>{question.question_option_4}</p>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              );
            })}
        </Slider>
      ) : (
        <div className="quiz_placeholderText">
          Hold on while we tune you in into the live stream. This will not take
          more than 45 seconds. Please do not exit.
        </div>
      )}
    </div>
  );
});
