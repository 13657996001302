import React from "react";
import { Route, Switch } from "react-router-dom";
import { ContextProvider } from "../shells/ContextProvider";
import Play from "../shells/Play";
import { BrowserRouter as Router } from "react-router-dom";
import { Results } from "../shells/Results";
import Lobby from "../shells/Lobby";
import history from "./history";

function Root() {
  return (
    <ContextProvider>
      <Router history={history}>
        <Route exact path="/" render={() => <Lobby />}></Route>
        <Route exact path="/play" render={() => <Play />}></Route>
        <Route exact path="/results" render={() => <Results />}></Route>
        <Route exact path="/lobby" render={() => <Lobby />}></Route>
      </Router>
    </ContextProvider>
  );
}
export default Root;
