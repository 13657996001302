import React, { useState } from "react";
import Collapsible from "react-collapsible";

export const LobbyRules = props => {
  const { quizRules } = props;
  const [expandShutter, setShutterExpand] = useState(false);

  const handleShutterClick = () => {
    setShutterExpand(!expandShutter);
  };

  return (
    <div className="root_lobbyrules">
      <div className="hdr_lobbyrules">
        <div className="lbl_lobbyrules">QUIZ RULES</div>
        <div className="expand_lobbyrules" onClick={handleShutterClick}>
          Know More
          <div className={expandShutter ? "arrow_up" : "arrow_down"}></div>
        </div>
      </div>
      <div className="body_lobbyrules">
        {/* {quizRules} */}
        <div className="txt_lobbyrules">1. 15 questions - 10 seconds each</div>
        <div className="txt_lobbyrules">
          2. Each question has 4 options for answers
        </div>
        <div className="txt_lobbyrules">
          3. Answer faster to get more points
        </div>
        <Collapsible open={expandShutter}>
          <div className="txt_lobbyrules">
            4. Terms &amp; Conditions Apply (
            <a href="https://www.onefc.com/one-quiz-tnc" target="_blank">
              https://www.onefc.com/one-quiz-tnc
            </a>
            )
          </div>
        </Collapsible>
      </div>
    </div>
  );
};
