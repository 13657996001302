import React from "react";

export const WinnerCard = props => {
  const { user_rank, user_score, total_participants, user_prize } = props;
  return (
    <div className="root_winnerCard">
      <div className="hdr_winnerCard">CONGRATULATIONS</div>
      <div className="reward_winnerCard">YOU WON!</div>
      <div className="prize_winnerCard">
        <img src={user_prize} />
      </div>
      <div className="score_winnerCard">Your Score: {user_score}</div>
      <div className="rank_winnerCard">
        Rank # {user_rank}/{total_participants}
      </div>
    </div>
  );
};

export const LostCard = props => {
  const { user_rank, user_score, total_participants } = props;
  return (
    <div className="root_lostCard">
      <div className="hdr_lostCard">YOU LOST</div>
      <div className="subhdr_lostCard">BETTER LUCK NEXT TIME</div>
      {/* <div className="prize_winnerCard">
        <img src="https://cdn-images.spcafe.in/img/es3-cfit-s72/Game/Games_Homes_Screen/Banners/pool_tab.png" />
      </div> */}
      <div className="score_winnerCard">Your Score: {user_score}</div>
      <div className="rank_winnerCard">
        Rank # {user_rank}/{total_participants}
      </div>
    </div>
  );
};
