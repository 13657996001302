import React, { useState, useEffect } from "react";
import { WinnerCard, LostCard } from "./header";
import { EnterEmailCard } from "./email";
import { Leaderboard } from "./leaderboard";
import { useHistory } from "react-router-dom";
import api from "../../utils/api";
import { LIVE_QUIZ_ID } from "../../utils/constants";

export const Results = () => {
  const [results, updateResults] = useState([]);
  const [didResultsApiFail, updateDidResultsApiFail] = useState(false);
  useEffect(() => {
    const fetchResults = async () => {
      const action = Object.assign({});
      action.handler = "V1_CHALLENGES_GET_QUIZ_RESULT";
      action.query = { live_quiz_id: LIVE_QUIZ_ID };
      const response = await api(action);
      if (response.status === 200) {
        updateResults(response.data);
      } else {
        console.log("results fail");
        updateDidResultsApiFail(true);
      }
    };
    fetchResults();
  }, []);

  const isWinner = (results && results.is_a_winner) || false;
  const isEmailRequired = (results && results.is_email_required) || false;

  if (results.length === 0 && !didResultsApiFail) {
    return (
      <div className="root_awaitingresults">
        Please wait while we fetch the results{" "}
      </div>
    );
  } else if (didResultsApiFail) {
    return <div className="root_awaitingresults">Something went wrong</div>;
  }
  return (
    <div className="root_results">
      {isWinner ? <WinnerCard {...results} /> : <LostCard {...results} />}
      {!isEmailRequired ? <EnterEmailCard /> : <div />}
      <Leaderboard results={results} />
    </div>
  );
};
