import React, { useEffect, useState } from "react";
import { LobbyHeader } from "./header";
import { LobbyTimer } from "./timer";
import { LobbyRules } from "./rules";
import { LobbyPrizes } from "./prizes";
import { EnterEmailCardLobby } from "./email";
import { withRouter } from "react-router-dom";
import api from "../../utils/api";
import { nanoid } from "nanoid";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";

const Lobby = props => {
  const [quizData, updateQuizData] = useState([]);
  const [countryCode, updateCountryCode] = useState("");
  const [countryName, updateCountryName] = useState("");
  useEffect(() => {
    const getQuiz = async () => {
      const nanoidValue = nanoid();
      const action = {};
      const action_2 = {};
      action.handler = "V1_CHALLENGES_GETCURRENTQUIZ";
      action_2.handler = "V1_COMMON_LAUNCH";
      action_2.query = { gid: nanoidValue };
      if (localStorage.getItem("token")) {
        const response = await api(action);
        if (response.status === 200) {
          updateQuizData(response.data);
        } else {
          console.log("Get current quiz failed!");
        }
      } else {
        const [response, response_2] = await Promise.all([
          api(action),
          api(action_2)
        ]);
        if (response_2.status === 200) {
          localStorage.setItem("token", response_2.data);
        }
        if (response.status === 200) {
          updateQuizData(response.data);
        }
        if (response.status !== 200) {
          console.log("Get current quiz failed!");
        }
      }
    };
    getQuiz();
    getGeoInfo();
  }, []);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => {
        const data = response.data || {};
        updateCountryCode(data.country_code);
        updateCountryName(data.country_name);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="root_lobby">
      <LobbyHeader />
      <LobbyTimer
        history={props.history}
        quizStartDate={quizData && quizData.start_time}
        countryCode={countryCode}
      />
      <LobbyRules quizRules={quizData && quizData.quiz_rules} />
      <LobbyPrizes quizPrizes={quizData && quizData.quiz_prizes} />
      <EnterEmailCardLobby />
      {countryCode ? (
        <div className="txt_lobbyCountry">
          You are playing from <ReactCountryFlag countryCode={countryCode} />{" "}
          {countryName}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default withRouter(Lobby);
