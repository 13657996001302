import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./header";
import Snackbar from "../Snackbar/index";
import api from "../../utils/api";
import { Questions } from "./questions";
import ReactPlayer from "react-player";
import { OPTION_TIME, LIVE_QUIZ_ID } from "../../utils/constants";

const delay = ms => new Promise(res => setTimeout(res, ms));

const Play = props => {
  const childRef = useRef();
  const playerRef = useRef();
  const [questionSet, updateQuestionSet] = useState([]);
  const [timer, updateTimer] = useState(-1);
  const [isStreamPlaying, updatePlayingState] = useState(false);
  const [showFirstQuestionBool, updateShowFirstQuestionBool] = useState(false);
  const [quizStreamTime, updateQuizStreamTime] = useState(0);
  const [showOptionBool, updateShowOptionBool] = useState(false);
  const [isVideoMuted, updateIsVideoMuted] = useState(true);
  const [isPaused, updateIsPaused] = useState(false);

  useEffect(() => {
    let apiPollCounter = 4500000;

    const getQuestion = async () => {
      const action = Object.assign({});
      action.handler = "V1_CHALLENGES_GETQUESTIONS";
      //to do
      action.query = { live_quiz_id: LIVE_QUIZ_ID };
      const response = await api(action);
      if (response.status === 200) {
        const questionSetUtil = response.data;
        updateQuestionSet(questionSetUtil);
      } else {
        console.log("question fetch fail");
      }
    };

    const getStreamTime = async () => {
      const action = Object.assign({});
      action.handler = "V1_GET_STREAM_SWITCH_TIME";
      //to do
      action.query = { live_quiz_id: LIVE_QUIZ_ID };
      while (apiPollCounter > 0) {
        const response = await api(action);
        apiPollCounter = apiPollCounter - 3;
        if (response.status === 200) {
          if (response.data !== -1) {
            apiPollCounter = 0;
            updateQuizStreamTime(response.data);
          }
        } else {
          console.log("fetch stream time fail");
        }
        await delay(3000);
      }
    };

    getQuestion();

    getStreamTime();

    handleOnStreamReady();
  }, []);

  useEffect(() => {
    if (questionSet && quizStreamTime) {
      showFirstQuestion(questionSet);
    }
  }, [quizStreamTime]);

  const showFirstQuestion = questionSet => {
    let questionOffset =
      (questionSet &&
        questionSet.length &&
        questionSet[0] &&
        questionSet[0].question_offset) ||
      0;
    let optionOffset =
      (questionSet &&
        questionSet.length &&
        questionSet[0] &&
        questionSet[0].option_offset) ||
      0;

    questionOffset = questionOffset;
    optionOffset = optionOffset;

    let exactVideoStreamTime = new Date(quizStreamTime);
    exactVideoStreamTime = exactVideoStreamTime.setSeconds(
      exactVideoStreamTime.getSeconds() + questionOffset
    );

    let exactShowOptionsTime = new Date(quizStreamTime);
    exactShowOptionsTime = exactShowOptionsTime.setSeconds(
      exactShowOptionsTime.getSeconds() + optionOffset
    );
    const currentDate = new Date();
    const timeoutInterval =
      new Date(exactVideoStreamTime).getTime() - currentDate.getTime();

    const optionTimeoutInterval =
      new Date(exactShowOptionsTime).getTime() - currentDate.getTime();

    const qTimer = setTimeout(() => {
      updateShowFirstQuestionBool(true);
      console.log("wait time for first question:" + timeoutInterval);
    }, timeoutInterval);

    const oTimer = setTimeout(() => {
      updateShowOptionBool(true);
      updateTimer(OPTION_TIME);
    }, optionTimeoutInterval);
  };

  useEffect(() => {
    handleTick();
  }, [timer]);

  const handleTick = () => {
    let timerTimeout = setTimeout(function() {
      if (timer < 0) {
        return;
      }
      if (timer === 0) {
        //clearInterval(timerTimeout);
        childRef.current.submitAndNav();
        return;
      } else {
        updateTimer(timer - 1);
      }

      //}
    }, 1000);
  };

  const handleOnStreamReady = () => {
    setTimeout(() => {
      updatePlayingState(true);
    }, 500);
  };

  const handlemuteclick = e => {
    console.log(playerRef.current);
    updateIsVideoMuted(!isVideoMuted);
  };

  const handlePause = () => {
    updateIsPaused(true);
  };

  const handlePlay = () => {
    if (isPaused) {
      playerRef.current.seekTo(0.9999);
    }
    updateIsPaused(false);
  };

  return (
    <div className="playContainer">
      {/* <Header timer={timer} updateTimer={updateTimer} /> */}
      <div className="videoSection">
        <ReactPlayer
          width={"100%"}
          height={"100%"}
          url="https://d24jedal514622.cloudfront.net/out/v1/445604c5f5964269b170b9e6fc8d85d4/index.m3u8"
          //url="https://www.youtube.com/watch?v=vksN1OITiX0"
          controls={false}
          playing={isStreamPlaying}
          onReady={handleOnStreamReady}
          playsinline
          muted={isVideoMuted}
          ref={playerRef}
          onPause={handlePause}
          onPlay={handlePlay}
          onBuffer={handlePause}
        />
      </div>
      {quizStreamTime ? (
        <React.Fragment>
          <Questions
            questionSet={questionSet}
            history={props.history}
            showFirstQuestionBool={showFirstQuestionBool}
            timer={timer}
            updateTimer={updateTimer}
            ref={childRef}
            quizStreamTime={quizStreamTime}
            showOptionBool={showOptionBool}
            updateShowOptionBool={updateShowOptionBool}
          />
          {/* <Snackbar /> */}
        </React.Fragment>
      ) : isVideoMuted ? (
        <React.Fragment>
          <div className="quiz_placeholderText">
            Your video is in mute. Please click the below button to start
            listening to your favorite star
          </div>
          <div onClick={handlemuteclick} className="btn_unmute">
            Unmute
          </div>
        </React.Fragment>
      ) : (
        <div className="quiz_placeholderText">
          Hold on while we tune you in into the live stream. This will not take
          more than 45 seconds. Please do not exit.
        </div>
      )}
    </div>
  );
};

export default withRouter(Play);
