import React from "react";

export const LobbyHeader = () => {
  return (
    <div className="root_lobbyheader">
      {/* <div className="logo_onechamp">
        <img src="" />
      </div> */}
      <div className="logo_quiz">
        <video
          src={"https://onequiz.games.onefc.com/assets/ONEQuiz_LOOP_NewLogo.mp4"}
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
    </div>
  );
};
