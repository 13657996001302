import React from "react";
import { FixedSizeList as List } from "react-window";
import ReactCountryFlag from "react-country-flag";

export const Leaderboard = props => {
  const { results } = props;
  const itemCount =
    (results && results.finalLeaderBoard && results.finalLeaderBoard.length) ||
    0;
  return (
    <div className="root_leaderboard">
      <div className="hdr_leaderboard">LEADERBOARD</div>
      <List
        height={230}
        itemCount={itemCount}
        itemSize={52}
        width={"100%"}
        itemData={results && results.finalLeaderBoard}
      >
        {LeaderboardRow}
      </List>
    </div>
  );
};

const LeaderboardRow = ({ data, index, style }) => {
  const dataUtil = (data && data[index]) || {};
  return (
    <div
      className={
        index % 2 ? "card_leaderboard " + "even_row" : "card_leaderboard"
      }
      style={style}
    >
      <div className="serial_leaderboard">{index + 1}</div>
      {dataUtil && dataUtil.country_code ? (
        getCountryFlag(dataUtil.country_code)
      ) : (
        <div />
      )}

      <div className="user_leaderboard">
        <div className="name_leaderboard">
          {(dataUtil && dataUtil.user_email) || "guest_" + dataUtil.user_id}
        </div>
        <div className="points_leaderboard">
          {dataUtil && dataUtil.user_points} Pts
        </div>
      </div>
      <div className="won_leaderboard">
        {dataUtil && dataUtil.is_a_winner ? "Won" : ""}
      </div>
    </div>
  );
};

const getCountryFlag = country_code => {
  return <ReactCountryFlag countryCode={country_code} style={flagStyle} />;
};

const flagStyle = {
  height: "1.19rem",
  width: "1.19rem",
  marginRight: "1rem",
  marginTop: "0.75rem",
  alignSelf: "center"
};
