import React from "react";

export const LobbyPrizes = props => {
  const { quizPrizes } = props;
  if (quizPrizes && quizPrizes.length) {
    return (
      <div className="root_lobbyprizes">
        <div className="hdr_lobbyprizes">TODAY&apos;S PRIZES</div>
        <div className="body_lobbyprizes">
          {quizPrizes.map(prize => {
            return (
              <div className="item_lobbyprizes" key={prize.id}>
                <img src={prize.img_link} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return <div />;
};
