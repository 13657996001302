import axios from "axios";
import routes from "./apiRoutes";

export default async function api(action) {
  const token = localStorage.getItem("token");
  const baseUrl = "https://onequizserver.azurewebsites.net";

  const form = action.containsFormData
    ? { "Content-Type": "multipart/form-data" }
    : {};
  const headers = Object.assign({}, form, { Authorization: token });

  try {
    const response = await axios({
      method: routes[action.handler].method,
      url: baseUrl + routes[action.handler].path,
      data: action.payload || null,
      params: action.query || null,
      headers
      // withCredentials: true
      // credentials: 'same-origin',
    });

    return response;
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        return error.response;
        //yield put({ type: "USER_LOGOUT" });
      } else {
        console.log(error);
        //yield put({ type: "UNCAUGHT_ERR", info: "Error", err: "error" });
        return error.response;
      }
    }

    console.log(error);
    return { data: { data: null }, error };
  }
}
