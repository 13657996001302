import React from "react";
import "./main.css";
import Root from "./root/index";
function App() {
  return (
    <div className="App">
      <Root />
    </div>
  );
}

export default App;
