const routes = {
  V1_CHALLENGES_GETCURRENTQUIZ: {
    path: "/livequiz/api/v1/challenges/getCurrentQuiz",
    method: "get"
  },
  V1_CHALLENGES_GETQUESTIONS: {
    path: "/livequiz/api/v1/challenges/getQuestions",
    method: "get"
  },
  V1_USER_SUBMIT_EMAIL: {
    path: "/livequiz/api/v1/user/submitEmail",
    method: "post"
  },
  V1_CHALLENGES_SUBMITANSWER: {
    path: "/livequiz/api/v1/challenges/submitAnswer",
    method: "post"
  },
  V1_CHALLENGES_GET_QUIZ_RESULT: {
    path: "/livequiz/api/v1/challenges/getQuizResult",
    method: "get"
  },
  V1_GET_STREAM_SWITCH_TIME: {
    path: "/livequiz/api/v1/challenges/getStreamTime",
    method: "get"
  },
  V1_COMMON_LAUNCH: {
    path: "/livequiz/api/v1/common/launch",
    method: "get"
  },
  V1_USER_SUBMIT_COUNTRY: {
    path: "/livequiz/api/v1/user/submitCntrycode",
    method: "post"
  }
};

export default routes;
