import React, { useState, useEffect } from "react";
import api from "../../utils/api";

export const LobbyTimer = props => {
  //const start_time = 1590435958454; //"2021-01-01";
  const start_time = props.quizStartDate;
  const [initial, setInitial] = useState(true);
  const [isJoinClicked, updateJoinClicked] = useState(false);

  const calcTimeLeft = () => {
    const diff = new Date(start_time) - new Date();
    let timeleft = {};
    if (diff === 0) {
      submitCountryCode(props.countryCode);
      props.history.push("play");
      return;
    } else if (diff > 0) {
      if (initial) {
        setInitial(false);
      }

      timeleft = {
        d: Math.floor(diff / (1000 * 60 * 60 * 24)),
        h: Math.floor((diff / (1000 * 60 * 60)) % 24),
        m: Math.floor((diff / 1000 / 60) % 60),
        s: Math.floor((diff / 1000) % 60)
      };

      return timeleft;
    } else {
      if (!initial) {
        submitCountryCode(props.countryCode);
        props.history.push("play");
      }
      // updateAlreadyStarted(true);
      return;
    }
  };
  const [timeleft, setTimeLeft] = useState(calcTimeLeft());

  useEffect(() => {
    const value = calcTimeLeft();
    setTimeout(() => {
      if (value === 0) {
      }
      setTimeLeft(value);
    }, 1000);
  });

  const timerComponent = [];

  timeleft &&
    Object.keys(timeleft).forEach(interval => {
      if (!timeleft[interval]) {
        return;
      }

      timerComponent.push(
        <span>
          {timeleft[interval]} {interval}{" "}
        </span>
      );
    });

  if (new Date(start_time) - new Date() < 0) {
    return (
      <div className="val_lobbytimer">Come back tomorrow for the next Quiz</div>
    );
  }

  return (
    <div className="root_lobbytimer">
      {timeleft && (timeleft["s"] || timeleft["m"] || timeleft["h"]) ? (
        <React.Fragment>
          <div className="lbl_lobbytimer">LIVE QUIZ STARTING IN</div>
          <div className="val_lobbytimer">
            {timerComponent.length ? timerComponent : ""}
          </div>
          {/* {isJoinClicked ? (
            <div className="btn_quizJoined">Quiz Joined!</div>
          ) : (
            <div
              className="btn_joinQuiz"
              onClick={() => updateJoinClicked(true)}
            >
              Click here to Join Quiz!
            </div>
          )} */}
        </React.Fragment>
      ) : (
        <div className="val_lobbytimer">
          {/* Come back tomorrow for the next Quiz */}
        </div>
      )}
    </div>
  );
};

const submitCountryCode = async countryCode => {
  const action = Object.assign({});
  action.handler = "V1_USER_SUBMIT_COUNTRY";
  action.payload = { country_code: countryCode };
  const response = await api(action);
  if (response.status === 200) {
    console.log("success");
  } else {
    console.log("submit code fail");
  }
};
