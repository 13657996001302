import React, { useState } from "react";
import api from "../../utils/api";

export const EnterEmailCard = () => {
  const [emailValue, updateEmailValue] = useState("");
  const [showSuccessSubmission, updateSubmission] = useState(false);
  const [emailError, updateEmailError] = useState("");

  const handleEmailChange = e => {
    if (e) {
      updateEmailValue(e.target.value);
    }
  };

  const submitEmail = () => {
    if (
      emailValue &&
      emailValue.indexOf("@") > -1 &&
      emailValue.indexOf(".") > -1
    ) {
      submitEmailApi();
    } else {
      updateEmailError("Please enter a valid email address!");
    }
  };

  const submitEmailApi = async () => {
    //alert("Your email ID is: " + emailValue);

    const action = Object.assign({});
    action.handler = "V1_USER_SUBMIT_EMAIL";
    action.payload = { user_email: emailValue };
    const response = await api(action);
    if (response.status === 200) {
      updateSubmission(true);
    } else {
      console.log("submit email fail");
    }
  };
  if (!showSuccessSubmission) {
    return (
      <div className="root_enteremail">
        <div className="hdr_enteremail">
          Provide your email address to claim winnings
        </div>
        {/* <div className="desc_enteremail">
          Kindly provide your email address so that you can be contacted for
          rewards
        </div> */}
        <div className="body_enteremail">
          <input
            type="text"
            placeholder="Enter your email address"
            value={emailValue}
            onChange={handleEmailChange}
            className="input_enteremail"
          />
          <div className="submit_enteremail" onClick={submitEmail}>
            SUBMIT
          </div>
        </div>
        {emailError ? <div className="err_email">{emailError}</div> : <div />}
      </div>
    );
  } else {
    return <GetInTouchCard />;
  }
};

export const GetInTouchCard = () => {
  return (
    <div className="root_getintouch">
      <div className="img_getintouch">
        <img />
      </div>
      <div className="txt_getintouch">
        Our team will get in touch with you shortly to give away your rewards
      </div>
    </div>
  );
};
